<template>
  <!--员工薪酬记录-->
  <div style="padding: 20px;">
    <div v-if="showDetails === false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="请输入关键字"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="触发时间:">
                    <!--<el-date-picker-->
                    <!--unlink-panels-->
                    <!-- v-model="where.value1"-->
                    <!-- type="daterange"-->
                    <!-- range-separator="至"-->
                    <!-- start-placeholder="开始日期"-->
                    <!-- end-placeholder="结束日期">-->
                    <!--</el-date-picker>-->
                    <el-date-picker
                        v-model="where.period"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择年月">
                    </el-date-picker>
                    <!-- <DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/> -->
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label=" ">
                    <div class="ele-form-actions">
                      <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                      </el-button>
                      <el-button @click="empty">清空</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <!--<el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-tianjia" />-->
                <!--  <span style="vertical-align: middle">发起对账</span>-->
                <!--</el-button>-->
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">合计应付薪资：</span>
                <span style="font-size: 18px;font-weight: 700;color: #fea837;">{{sum.count_sum}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">实付薪资：</span>
                <span style="font-size: 18px;font-weight: 700;color: #7958C7;">{{sum.already_sum}}</span>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <!--&lt;!&ndash;自定义列显示&ndash;&gt;-->
              <!--<template v-slot:status= "{ scope }">-->
              <!--  <div v-if="scope.row.status === 0">-->
              <!--    <span style="width: 8px;height: 8px;background: #FF9E1C;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>-->
              <!--    <span>待收款</span>-->
              <!--  </div>-->
              <!--  <div v-else>-->
              <!--    <span style="width: 8px;height: 8px;background: #25EB61;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>-->
              <!--    <span>已收款</span>-->
              <!--  </div>-->
              <!--</template>-->

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" v-if="scope.row.check_status != 1" @click="openEdit(scope.row)">审核</el-link>
                <!--<el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">-->
                <!--  <el-link slot="reference" :underline="false" style="margin-left: 10px;">删除</el-link>-->
                <!--</el-popconfirm>-->
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--详情组件-->
    <Details v-if="showDetails === true" :data="cummont" @showDetails="showdetails"/>

  </div>
</template>

<script>
//引入详情组件
import Details from './details.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'
import {staff_emolument_list} from "@/api/finance";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    Details
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        // {
        //   label: "编号",
        //   // type: "index",
        //   // width: "70"
        //   prop: "id",
        //   fixed:'left',
        //   isShow: true
        // },
        // {
        //   label: "姓名",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        // {
        //   label: "基本工资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "岗位工资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "项目工资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "班次补助",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "全勤工资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "项目奖金",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "月度奖金",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "利润奖金",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "项目提成",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "无投诉奖励",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "无催单奖励",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "取消薪资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "夜班奖励",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "夜班全勤",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "合格率奖励",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "合格薪资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "月度营业额提成",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "月度利润提成",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "考核奖罚",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "周期",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "应付薪资",
        //   prop: "id",
        //   isShow: true
        // },
        // {
        //   label: "操作",
        //   width: "100",
        //   slot: "operationSlot",
        //   fixed:'right',
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //详情组件
      cummont:null,
      showDetails:false,

      sum:{
        already_sum:'',
        count_sum:'',
      }

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      staff_emolument_list(this.where).then(async (res) => {
        this.total = res.data.count;
        this.columns = res.data.header;
        this.List = res.data.list;
        this.sum = res.data.sum;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择日期
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //打开详情
    openEdit(row){
      this.cummont = row;
      this.showDetails = true;
    },
    //接收详情子组件数据
    showdetails(value){
      this.showDetails = value;
      this.getList();
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
